import React, { useState } from 'react';

type InteractiveImageData =
  | { kind: "GridBox"; images: string[] }
  | { kind: "Turn"; images: ["red", "blue"] }
  | { kind: "GameMode"; images: ["General", "Simple"] }
  | { kind: "Score"; images: [] }
  | { kind: "MoveChoice"; images: string[] };

interface InteractiveImageButtonProps {
  data: InteractiveImageData;
  style?: React.CSSProperties;
}

const InteractiveImageButton: React.FC<InteractiveImageButtonProps> = ({ data, style }) => {
  const [index, setIndex] = useState(0); // Index to keep track of the current image/caption

  // Function to handle button clicks
  const handleClick = () => {
    if (data.kind === "Score") {
      setIndex(prevScore => prevScore + 1);
    } else {
      setIndex((prevIndex) => (prevIndex + 1) % data.images.length);
    }
  };

  return (
    <button style={style} onClick={handleClick}>
      <div className="content">
        {data.kind !== "Score" && <img src={data.images[index]} alt="" className="image" />}
        {data.kind === "Score" && <div>{index}</div>}
      </div>
    </button>
  );
};

export default InteractiveImageButton;
export type { InteractiveImageData };