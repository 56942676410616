import React from 'react';
import './ImageButton.css';

interface ImageButtonProps {
  image: string;
  caption: string;
  to: string;
  className?: string;
}

const ImageButton: React.FC<ImageButtonProps> = ({ image, caption, to, className }) => {
  return (
    <a href={to} className={className} target="_blank" rel="noopener noreferrer"> {/* Use an <a> tag with target="_blank" */}
      <div className="content">
        <img src={image} alt={caption} className="image" />
        <div className="caption">{caption}</div>
      </div>
    </a>
  );
};

export default ImageButton;
