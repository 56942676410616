import React from 'react';
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Link,
} from 'react-router-dom';
import './App.css';
import profileImage from './images/Profile.jpg'; // Import your profile image here
import GeneticAlgorithm from './images/GeneticAlgorithm.jpg';
import BlobCivJava from './images/BlobCivJava.jpg';
import CplusplusTemplates from './images/CplusplusTemplates.jpg';
import ImageButton from './ImageButton'; // Import ImageButton component
import SosGame from './images/SOSGAME.jpg';
import FindRoute from './images/Find-Route.jpg';
import UnderMaintenance from "./images/Maintenance.jpg";
import ScannerParser from "./images/ScannerParser.jpg";
import Website from "./images/Website.jpg";
import AboutImage from "./images/AboutImage.jpg";
import Fundraiser from "./images/Fundraiser.jpg";
//import Crud from "./images/crudyimage.jpg";
import OracleCernerLogo from "./images/OracleCerner.jpg";
import CernerLogo from "./images/cerner.png";
import SOSGrid from "./images/grid.png";
import SOSLetterO from "./images/O.png";
//import SOSLetterORed from "./images/ORed.png";
//import SOSLetterOBlue from "./images/OBlue.png";
import SOSLetterS from "./images/S.png";
//import SOSLetterSRed from "./images/SRed.png";
//import SOSLetterSBlue from "./images/SBlue.png";ckhurst
import InteractiveImageButton from './InteractiveImageButton';
//import Grid from './Grid';
import { InteractiveImageData } from './InteractiveImageButton';
import DogMail from './images/dogmail.gif';
//import RooLogo from './images/RooLogo.jpg';
import UMKCLogo from './images/UMKCLogo.jpg';
//import TimeDisplay from './Time/TimeDisplay';



const Home: React.FC = () => {
  return (
      <div className="home-container">
      <div className="profile-image-container">
        <img src={profileImage} alt="Professional Photo" style={{ maxWidth: '100%'}} />
      </div>
      {/*
      <div className="umkc-image-container">
        <img src={RooLogo} alt="Professional Photo" style={{ maxWidth: '100%'}} />
      </div>
      */}
      <div style={{ flex: 1, padding: '3rem' }}>
        <div style={{ textAlign: 'center' }}>
          <h1>Joshua Newton</h1>
          <h2>Skills</h2>
        </div>
        <div className="two-columns">
          <ul className="column">
            <li>C# .NET   </li>
            <li>Python </li>
            <li>Node </li>
            <li>Web Hosting </li>
            <li>Django </li>
            <li>SQL </li>
            <li>REST API </li>
          </ul>
          <ul className="column">
            <li>C++</li>
            <li>Java</li>
            <li>Javascript</li>
            <li>ReactJS</li>
            <li>Typescript</li>
            <li>Agile</li>
          </ul>
        </div>
        <div style={{ textAlign: 'center' }}>
          <h1>Experience</h1>
          <a href="JoshuaNewton-Resume.pdf" className ="pdf-link" target="_blank" rel="noopener noreferrer">Click Here To View Resume PDF</a>
          <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <h2>Oracle's Cerner</h2>
            <img src={OracleCernerLogo} alt="oracle's cerner" style={{ marginLeft: '15px', height: '50px', width: 'auto' }}/>
        </div> 
          <h3>Technical Apprentice</h3>
          <h4>08/2019 to 06/2023</h4>
        </div>
        <div>
          <ul>
            <li>Worked on the frontend for the Cerner Proprietary Mobile Care Nursing Appllication using ReactJS and Typescript</li>
            <li>Experience with Jira Sprints</li>
            <li>Ran Xcode iphone simulator and locahost tests</li>
            <li>Wrote manual test outlines</li>
            <li>Created a weekly password decrypter for associates to sign in to a work application</li>
            <li>Refactored Jenkins pipelines</li>
            <li>Updated NPM Dependencies</li>
          </ul>
        </div>
        <div style={{ textAlign: 'center' }}>
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <h2>Cerner</h2>
            <img src={CernerLogo} alt="cerner" style={{ marginLeft: '15px', height: '55px', width: 'auto' }}/>
        </div>  
          <h3>Highschool Intern</h3>
          <h4>01/2019 to 04/2019</h4>
        </div>
        <div>
          <ul>
            <li>Laptop Repair for corporate associates</li>
          </ul>
        </div>
        <div style={{ textAlign: 'center' }}>
          <h1>Education</h1>
          <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <h2>Bachelor of Science: Computer Science</h2>
            <img src={UMKCLogo} alt="umkc-logo" style={{ marginLeft: '10px', height: '50px', width: 'auto' }}/>
        </div>  
        </div>
        <h4>University of Missouri - Kansas City, MO</h4>
        <p>Graduated 07/2023</p>
        <h4>Notable Grades</h4>
        <li>COMP-SCI 461 - Intro Artificial Intelligence - A-</li>
        <li>COMP-SCI 451R - Software Engineering Capstone - B</li>
        <li>COMP-SCI 441 - Progrm Lang:Desgn & Imp - B+</li>
        <li>COMP-SCI 491 - Internship 2022 Summer Semester - CR</li>
        <li>ANCH 150 - Computing/Eng-Society - A</li>
        <div> 
        
        </div>
      </div>
    </div>
  );
};
// https://media.tenor.com/tkClXEm2o24AAAAM/her-comes-the-mail-mail-dog.gif
const About: React.FC = () => {
  return (
    <div className="about-container">
      <div className="about-image-container">
        <img src={AboutImage} alt="Your Name" className="profile-image" />
      </div>
      <h1>About Me</h1>
      <p>
      Recently graduated from the University of Missouri-Kansas City (UMKC) and am currently pursuing new and challenging coding projects to hone my skills and apply myself for impactful environments to come. On a more personal note, I enjoy hiking, reading, and am currently attempting to learn Latin. Always open to new opportunities and connections, feel free to reach out!
      </p>
      <p style={{ fontStyle: 'italic', fontSize: '14px' }}>
  Can you guess my favorite song in latin "Pluvia visem est semper tu?" if not then click here... 
  <a 
    href="https://www.youtube.com/watch?v=bO28lB1uwp4" 
    target="_blank" 
    rel="noopener noreferrer" 
    style={{ textDecoration: 'none', color: '#007bff', fontStyle: 'italic', fontSize: '14px' }}
  >
    YouTube Link
  </a>.
</p>


    </div>
  );
};

const Games: React.FC = () => {
  const projects = [
    { image: UnderMaintenance, caption: 'Interactive Online SOS Game', to: '/SOSGame' },
  ];

  return (
    <div>
      <h1>Interactive Website Games</h1>
      <div
        className="projects-container"
        style={{
          maxWidth: '1350px', // Set a fixed width for the container div
          margin: '0 auto', // Center the container div on the page
        }}
      >
        <div className="Projects">
          {projects.map((project, index) => (
            <ImageButton
              key={index}
              image={project.image}
              caption={project.caption}
              to={project.to}
              className="image-button"
            />
          ))}
        </div>
      </div>
    </div>
  );
};

// Define the data for the buttons
const buttonData: InteractiveImageData = {
  kind: "GridBox",
  images: [SOSGrid, SOSLetterO, SOSLetterS]
};

const SOSGame: React.FC = () => {
  return (
    <div>
      <h1>Interactive Website Games</h1>
      <div
        className="projects-container"
        style={{
          maxWidth: '1350px',
          margin: '0 auto',
          display: 'grid', // Apply grid layout
          gridTemplateColumns: 'repeat(3, 1fr)', // Create 3 columns of equal width
          gridTemplateRows: 'repeat(3, 1fr)', // Create 3 rows of equal height
          gap: '1em' // Set a gap between grid items
        }}
      >
        {
          // Create 9 buttons for the grid
          Array(9).fill(0).map((_, index) => (
            <InteractiveImageButton 
              key={index}
              data={buttonData}
              style={{
                width: '100%', // Button should fill its grid cell
                height: 'auto', // Maintain aspect ratio
              }}
            />
          ))
        }
        <InteractiveImageButton 
          data={{kind: "Score", images: []}}
          style={{
            gridColumn: '1 / span 3', // Span the score button across all columns
          }}
        />
      </div>
    </div>
  );
};

const Contact: React.FC = () => {
  return (
    <div>
      <div className="gif-container">
        <img src={DogMail} alt="Dog Mail Gif" className="gif-image" />
      </div>
      <h2>For further inquiries: newtonjoshjr@gmail.com</h2>
    </div>
  );
};

{/* { image: Crud, caption: 'CRUD', to: 'https://github.com/Jan4fx/crudy' }, <Route path="/games" element={<Games />} /> */}
const Projects: React.FC = () => {
  const projects = [
    { image: BlobCivJava, caption: 'Blob Survival Sim', to: 'https://github.com/Jan4fx/BlobCivJava' },
    { image: CplusplusTemplates, caption: 'C++ Examples', to: 'https://github.com/Jan4fx/CplusplusTemplates' },
    { image: GeneticAlgorithm, caption: 'Genetic Algorithm', to: 'https://github.com/Jan4fx/geneticalgorithm' },
    { image: Website, caption: 'Portfolio Website', to: 'https://gitlab.com/Jan4fx/my-portfolio' },
    { image: FindRoute, caption: 'Find-Route', to: 'https://github.com/Jan4fx/find-route' },
    { image: ScannerParser, caption: 'Racket Parser/Scanner', to: 'https://github.com/Jan4fx/parser' },
    { image: SosGame, caption: 'SOS Game', to: 'https://github.com/Jan4fx/sosgame' },
    { image: Fundraiser, caption: 'Fundraising Website', to: 'https://gitlab.com/jonathanbenson/fundraiser-app' },
  ];

  return (
    <div>
      <h1>My Coding Projects</h1>
      <div
        className="projects-container"
        style={{
          maxWidth: '1350px', // Set a fixed width for the container div
          margin: '0 auto', // Center the container div on the page
        }}
      >
        <div className="Projects">
          {projects.map((project, index) => (
            <ImageButton
              key={index}
              image={project.image}
              caption={project.caption}
              to={project.to}
              className="image-button"
            />
          ))}
        </div>
      </div>
    </div>
  );
};
{/*             <li>
            <Link to="/games" className="link-size">Games</Link>
            </li>
*/}
const App: React.FC = () => {
  return (
    <Router>
      <div className="App">
        <nav>
          <ul>
            <li>
            <Link to="/" className="link-size">Home</Link>
            </li>
            <li>
              <Link to="/projects" className="link-size">Projects</Link>
            </li>
            <li>
            <Link to="/about" className="link-size">About</Link>
            </li>
            <li>
            <Link to="/contact" className="link-size">Contact</Link>
            </li>
            {/*<li>
              <TimeDisplay />
            </li> }*/}
          </ul>
        </nav>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/projects" element={<Projects />} />
          <Route path="/about" element={<About />} />
          <Route path="/SOSGame" element={<SOSGame />} />
          <Route path="/contact" element={<Contact />} />
        </Routes>
      </div>
    </Router>
  );
};

export default App;
